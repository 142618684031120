import { ErrorTemplate, Seo, TextLink } from '@app/components';

Custom404.layout = "none";

export default function Custom404() {
  const information = {
    errorCode: 404,
    heading: <>Sorry, we couldn&apos;t find the page you are looking for.</>,
    introText: <><p>The requested URL was not found on server.</p><p>&nbsp;</p><p>If you would like to report this problem, please <TextLink href='mailto:subscriptions@asianinvestor.net'>contact us</TextLink> and let us know what page you are looking for. Alternatively, you can visit the <TextLink href='/'>Homepage</TextLink></p></>
  }

  return (
   <>
    <Seo.Static name={"Page Not Found"} />
    <ErrorTemplate {...information} />
   </>
  )
}